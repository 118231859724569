/* eslint-disable prefer-spread */
import {
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ZoomIn, ZoomOut } from '@material-ui/icons';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import 'styles/pdfDialog.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PdfDialogInterface {
  path: string;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme) => ({
  dialogColor: {
    backgroundColor: theme.palette.action.focus,
  },
  pages: {
    margin: theme.spacing(1),
  },
  divZoom: {
    position: 'fixed',
    bottom: 70,
    left: '50%',
    marginLeft: -68,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius * 5,
    boxShadow: theme.shadows[20],
  },
  loadPdf: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '250px',
    padding: theme.spacing(2, 0, 2, 0),
  },
}));

const PdfDialog = ({
  path,
  open,
  handleClose,
}: PdfDialogInterface): JSX.Element => {
  const classes = useStyles();
  const [pages, setPages] = useState(null);
  const [scale, setScale] = useState(1.0);
  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };
  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setPages(numPages);
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setPages(null);
      }}
      maxWidth="lg"
      fullWidth
      onContextMenu={(e) => e.preventDefault()}
    >
      <Grid container justify="center" className={classes.dialogColor}>
        <Grid item>
          <Document
            file={path}
            onLoadSuccess={onDocumentLoadSuccess}
            className="pdf-container"
            externalLinkTarget="_blank"
            loading={
              <div className={classes.loadPdf}>
                <Typography variant="body1" component="p">
                  Carregando o arquivo...
                </Typography>
                <CircularProgress />
              </div>
            }
            error="Erro ao abrir o arquivo."
          >
            {Array.apply(null, Array(pages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page
                  pageNumber={page}
                  scale={scale}
                  className={classes.pages}
                  key={page}
                />
              ))}
          </Document>
        </Grid>
      </Grid>
      <div className={classes.divZoom}>
        <IconButton color="primary" onClick={zoomOut} disabled={isMinZoom}>
          <ZoomOut />
        </IconButton>
        <Typography>{(scale * 100).toFixed()}%</Typography>
        <IconButton color="primary" onClick={zoomIn} disabled={isMaxZoom}>
          <ZoomIn />
        </IconButton>
      </div>
    </Dialog>
  );
};

export default PdfDialog;
