import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  Apartment,
  ChevronLeft,
  ChevronRight,
  Dashboard,
  Delete,
  Edit,
  ExitToApp,
  Group,
  Info,
  Search,
} from '@material-ui/icons';
import clsx from 'clsx';
import { UserInterface } from 'hooks/useAuth';
import useRequireAuth from 'hooks/useRequireAuth';
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import headerStyles from 'styles/header';

import logoImg from '../assets/logo.png';
import profileImg from '../assets/profile.png';
import FormProfileDialog from './formProfileDialog';
import TooltipCustom from './tooltip';

const Header: React.FC = ({ children }) => {
  const auth = useRequireAuth();
  const classes = headerStyles();
  const router = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  const loadUser = () => {
    auth.checkLogin();
  };

  const getSearch = async () => {
    if (searchValue === '') {
      router.push('/');
    } else {
      router.push(`/search/${searchValue}`);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.divHeaderItem}>
            {/* <img src={logoImg} alt="logo" width={24} height={40} /> */}
            <Hidden smDown>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                GED - Digitalização e Gerenciamento Eletrônico de Documentos
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                GED
              </Typography>
            </Hidden>
          </div>
          <Hidden mdDown>
            <TextField
              placeholder="Pesquisar"
              variant="outlined"
              className={classes.searchBar}
              fullWidth
              size="small"
              InputProps={{
                className: classes.searchBarInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => setSearchValue(event.target.value)}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  getSearch();
                }
              }}
            />
          </Hidden>
          <div className={classes.divHeaderItem}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.nameProfile}
            >
              Olá {(auth.user as UserInterface)?.login}
            </Typography>
            <div className={classes.divEditProfile}>
              <img
                src={
                  (auth.user as UserInterface)?.photoUrl
                    ? (auth.user as UserInterface)?.photoUrl
                    : profileImg
                }
                alt="foto de perfil"
                width={45}
                height={45}
                className={classes.imgProfile}
              />
              <IconButton
                color="inherit"
                className={classes.btnEditProfile}
                size="small"
                onClick={handleDialogToggle}
              >
                <Edit />
              </IconButton>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openDrawer,
            [classes.drawerClose]: !openDrawer,
          }),
        }}
      >
        <Toolbar />
        <div
          className={clsx(classes.headerMenu, {
            [classes.headerMenuOpen]: openDrawer,
            [classes.headerMenuClose]: !openDrawer,
          })}
        >
          <Typography className={clsx({ [classes.hidden]: !openDrawer })}>
            Menu
          </Typography>
          <TooltipCustom
            title="Expandir"
            placement="right"
            className={clsx({ [classes.hidden]: openDrawer })}
          >
            <IconButton color="inherit" onClick={handleDrawerToggle}>
              <ChevronRight />
            </IconButton>
          </TooltipCustom>
          <TooltipCustom
            title="Ocultar"
            placement="right"
            className={clsx({ [classes.hidden]: !openDrawer })}
          >
            <IconButton color="inherit" onClick={handleDrawerToggle}>
              <ChevronLeft />
            </IconButton>
          </TooltipCustom>
        </div>

        <List component="nav">
          <Divider />
          <Link to="/" className={classes.link}>
            <ListItem button>
              <TooltipCustom
                title={openDrawer ? '' : 'Dashboard'}
                placement="right"
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <Dashboard color="primary" />
                </ListItemIcon>
              </TooltipCustom>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>

          {(auth.user as UserInterface)?.isAdmin === 1 && (
            <>
              <Link to="/company" className={classes.link}>
                <ListItem button>
                  <TooltipCustom
                    title={openDrawer ? '' : 'Cadastro de Empresas'}
                    placement="right"
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <Apartment color="primary" />
                    </ListItemIcon>
                  </TooltipCustom>
                  <ListItemText primary="Cadastro de Empresas" />
                </ListItem>
              </Link>

              <Link to="/users" className={classes.link}>
                <ListItem button>
                  <TooltipCustom
                    title={openDrawer ? '' : 'Cadastro de Usuários'}
                    placement="right"
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <Group color="primary" />
                    </ListItemIcon>
                  </TooltipCustom>
                  <ListItemText primary="Cadastro de Usuários" />
                </ListItem>
              </Link>

              <Link to="/logs" className={classes.link}>
                <ListItem button>
                  <TooltipCustom
                    title={openDrawer ? '' : 'Logs de Usuários'}
                    placement="right"
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <Info color="primary" />
                    </ListItemIcon>
                  </TooltipCustom>
                  <ListItemText primary="Logs de Usuários" />
                </ListItem>
              </Link>

              <Link to="/trash" className={classes.link}>
                <ListItem button>
                  <TooltipCustom
                    title={openDrawer ? '' : 'Lixeira'}
                    placement="right"
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <Delete color="primary" />
                    </ListItemIcon>
                  </TooltipCustom>
                  <ListItemText primary="Lixeira" />
                </ListItem>
              </Link>
            </>
          )}
        </List>

        <div className={classes.logout}>
          <Divider />
          <ListItem button onClick={auth.signOut}>
            <TooltipCustom title={openDrawer ? '' : 'Sair'} placement="right">
              <ListItemIcon className={classes.listItemIcon}>
                <ExitToApp color="primary" />
              </ListItemIcon>
            </TooltipCustom>
            <ListItemText primary="Sair" />
          </ListItem>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
      {auth.user && (
        <FormProfileDialog
          open={openDialog}
          handleClose={handleDialogToggle}
          user={auth.user as UserInterface}
          loadUser={loadUser}
        />
      )}
    </div>
  );
};

export default Header;
