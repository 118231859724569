import { makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const headerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    // flexGrow: 1,
    paddingLeft: theme.spacing(3),
  },
  divHeaderItem: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchBar: {
    maxWidth: 430,
  },
  searchBarInput: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
  logout: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  headerMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
  },
  headerMenuOpen: {
    paddingLeft: theme.spacing(2) + 1,
    paddingRight: theme.spacing(0.5),
  },
  headerMenuClose: {
    paddingLeft: theme.spacing(0.5),
  },
  listItemIcon: {
    minWidth: 0,
    paddingRight: 15,
  },
  hidden: {
    display: 'none',
  },
  nameProfile: {
    paddingRight: theme.spacing(2),
    textTransform: 'capitalize',
  },
  divEditProfile: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgProfile: {
    borderRadius: theme.shape.borderRadius * 6,
  },
  btnEditProfile: {
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 2,
    width: 45,
    height: 45,
    color: 'rgba(0,0,0,0)',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.action.active,
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
}));

export default headerStyles;
