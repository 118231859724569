import { Button, Paper, Typography } from '@material-ui/core';
import InputPassword from 'components/inputPassword';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import api from 'services/api';

import blackRedLogo from 'assets/black_red_logo.png';

import authStyles from 'styles/auth';

interface Inputs {
  password: string;
  confirmPassword: string;
}

const ChangePassword: React.FC = () => {
  const { token } = useParams<Record<string, string | undefined>>();
  const router = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = authStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [recoverId, setRecoverId] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>();
  const passwordRef = useRef('');
  passwordRef.current = watch('password', '');

  const verifyToken = async () => {
    const response = await api.get(`/auth/verify-token/${token}`);

    if (response.data.error) {
      router.replace('/404');
    } else {
      setRecoverId(response.data.recoverId);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const onSubmit = async (values: Inputs) => {
    const response = await api.post('/auth/new-password', {
      recoverId,
      newPassword: values.password,
    });

    if (response.data.error) {
      enqueueSnackbar(
        'Ocorreu um falha no processo! Tente novamente mais tarde.',
        {
          variant: 'error',
          persist: false,
        }
      );
    } else {
      enqueueSnackbar('Senha alterada com sucesso!', {
        variant: 'success',
        persist: false,
      });
    }

    router.push('/auth');
  };

  const openPage = () => {
    window.open('https://www.blackredsistemas.com.br', '_blank');
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Alterar Senha
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <InputPassword
            label="Nova Senha"
            typePassword={showPassword}
            changeTypePassword={() => setShowPassword(!showPassword)}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            {...register('password', { required: 'Este campo é obrigatório.' })}
          />
          <InputPassword
            label="Repita a Senha"
            typePassword={showConfirmPassword}
            changeTypePassword={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
            error={!!errors.confirmPassword?.message}
            helperText={errors.confirmPassword?.message}
            {...register('confirmPassword', {
              validate: (value) =>
                value === passwordRef.current || 'As senhas não coincidem',
            })}
          />

          <div className={classes.divBtn}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnSubmit}
              type="submit"
            >
              Alterar
            </Button>
          </div>
        </form>
      </Paper>

      <div
        className={classes.blackRedLogo}
        onClick={openPage}
        onKeyDown={openPage}
        role="button"
        tabIndex={0}
      >
        <Typography variant="subtitle1" component="p">
          Desenvolvido por
        </Typography>
        <img src={blackRedLogo} alt="Logo da Black Red Sistemas" height={60} />
      </div>
    </div>
  );
};

export default ChangePassword;
