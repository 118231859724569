import Header from 'components/header';
import { useAuth, UserInterface } from 'hooks/useAuth';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ChangePassword from 'pages/auth/ChangePassword';
import EmailSuccess from 'pages/auth/EmailSuccess';
import Login from 'pages/auth/Login';
import RecoverPassword from 'pages/auth/RecoverPassword';
import Company from 'pages/Company';
import Dashboard from 'pages/Dashboard';
import Folder from 'pages/Folder';
import Log from 'pages/Log';
import NotFound from 'pages/NotFount';
import Search from 'pages/Search';
import Trash from 'pages/Trash';
import User from 'pages/User';

const Routes = (): JSX.Element => {
  const auth = useAuth();

  return (
    <Switch>
      <Route path="/404" component={NotFound} />

      <Route path="/login" component={Login} exact />
      <Route path="/login/recover-password" component={RecoverPassword} exact />
      <Route path="/login/email-success" component={EmailSuccess} exact />
      <Route path="/login/:token" component={ChangePassword} />

      <Header>
        <Route path="/" component={Dashboard} exact />
        <Route path="/folder/:parentId" component={Folder} />
        <Route path="/search/:searchText" component={Search} />
        {(auth.user as UserInterface)?.isAdmin === 1 && (
          <>
            <Route path="/company" component={Company} />
            <Route path="/users" component={User} />
            <Route path="/logs" component={Log} />
            <Route path="/trash" component={Trash} />
          </>
        )}
      </Header>

      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
