import { createMuiTheme } from '@material-ui/core/styles';
import { ptBR } from '@material-ui/data-grid';

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#125F7D',
      },
      secondary: {
        main: '#4CAF50',
      },
      text: {
        primary: '#125F7D',
      },
      background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
      },
    },
  },
  ptBR
);

export default theme;
