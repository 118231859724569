import {
  IconButton,
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React from 'react';

interface TextFieldPasswordProps extends StandardTextFieldProps {
  typePassword: boolean;
  changeTypePassword: () => void;
}

const InputPassword = React.forwardRef(
  (
    { typePassword, changeTypePassword, ...props }: TextFieldPasswordProps,
    ref
  ): JSX.Element => (
    <TextField
      innerRef={ref}
      label="Senha"
      fullWidth
      margin="normal"
      type={typePassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={changeTypePassword} edge="end">
              {typePassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  )
);

export default InputPassword;
