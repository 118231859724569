import {
  Backdrop,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import MenuDialog from 'components/menuDialog';
import PdfDialog from 'components/pdfDialog';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import api from 'services/api';
import 'moment/locale/pt-br';

import fileImg from 'assets/file.png';
import folderEmptyImg from 'assets/folder_empty.png';
import folderImg from 'assets/folder.png';

import folderStyles from 'styles/folder';

const Folder: React.FC = () => {
  const { searchText } = useParams<Record<string, string | undefined>>();
  const auth = useAuth();
  const router = useHistory();
  const classes = folderStyles();
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [countFound, setCountFound] = useState(0);
  const [openDialogPdf, setOpenDialogPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const loadContent = async () => {
    setLoading(true);
    const response = await api.get(`/search/${searchText}`);
    setFolders(response.data.folders);
    setFiles(response.data.files);
    setCountFound(response.data.countFound);
    setLoading(false);
  };

  useEffect(() => {
    loadContent();
  }, [searchText, auth.user]);

  const handlePdfDialog = () => {
    setOpenDialogPdf(!openDialogPdf);
  };

  const onClickFolder = (row) => {
    router.push(`/folder/${row.id}`);
  };

  const onClickFile = async (row) => {
    const response = await api.get(`/file/url/${row.id}`);

    setPdfUrl(response.data.url);
    handlePdfDialog();
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <div>
          <Typography variant="body1" component="p">
            Pesquisando, aguarde...
          </Typography>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>

      <Grid
        container
        justify="flex-start"
        alignItems="center"
        className={classes.countFoundText}
      >
        <Typography component="h1" variant="h5">
          {countFound} resultados para {searchText}
        </Typography>
      </Grid>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={120} />
              <TableCell>Descrição</TableCell>
              <TableCell align="center">Modificado</TableCell>
              <TableCell align="center">Tamanho</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {folders.map((row) => (
              <TableRow key={row.id} className={classes.row}>
                <TableCell
                  onClick={() => {
                    onClickFolder(row);
                  }}
                >
                  <img
                    src={row.isEmpty ? folderEmptyImg : folderImg}
                    alt="Pasta"
                    height={50}
                  />
                </TableCell>
                <TableCell
                  onClick={() => {
                    onClickFolder(row);
                  }}
                >
                  {row.name}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    onClickFolder(row);
                  }}
                >
                  {moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    onClickFolder(row);
                  }}
                >
                  ---
                </TableCell>
                <TableCell align="center">
                  <MenuDialog
                    key={row.id}
                    id={row.id}
                    name={row.name}
                    type="folder"
                    loadContent={loadContent}
                    permissions={row.permissions}
                  />
                </TableCell>
              </TableRow>
            ))}

            {files.map((row) => (
              <TableRow key={row.id} className={classes.row}>
                <TableCell
                  onClick={() => {
                    onClickFile(row);
                  }}
                >
                  <img src={fileImg} alt="Pasta" height={50} />
                </TableCell>
                <TableCell
                  onClick={() => {
                    onClickFile(row);
                  }}
                >
                  {row.fileName}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    onClickFile(row);
                  }}
                >
                  {moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    onClickFile(row);
                  }}
                >
                  {(row.size / 1000000).toFixed(2)} MB
                </TableCell>
                <TableCell align="center">
                  <MenuDialog
                    key={row.id}
                    id={row.id}
                    name={row.fileName}
                    type="file"
                    loadContent={loadContent}
                    permissions={row.permissions}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PdfDialog
        path={pdfUrl}
        open={openDialogPdf}
        handleClose={handlePdfDialog}
      />
    </>
  );
};

export default Folder;
