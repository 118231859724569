import { makeStyles } from '@material-ui/core';

const folderStyles = makeStyles((theme) => ({
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  toolbarFolder: {
    background: theme.palette.action.focus,
    margin: theme.spacing(-3, -3, 1, -3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  btnUpload: {
    marginLeft: theme.spacing(2),
  },
  textNotPermission: {
    textAlign: 'center',
    marginTop: theme.spacing(5),
  },
  countFoundText: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    '& div': {
      display: 'flex',
      width: '250px',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
}));

export default folderStyles;
