import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import download from 'downloadjs';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import api from 'services/api';

interface MenuInterface {
  id: string;
  name: string;
}

const MenuDialogMenu = ({ id, name }: MenuInterface): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDownload = async () => {
    handleClose();
    enqueueSnackbar('Verificando e preparando a pasta para donwload...', {
      variant: 'info',
      persist: false,
    });

    api
      .get(`/drive/download/${id}`, {
        responseType: 'blob',
      })
      .then((res) => {
        download(res.data, `${name}.zip`);
      })
      .catch(() => {
        enqueueSnackbar(
          'Você não possui permissão para realizar o download desta pasta.',
          {
            variant: 'warning',
            persist: false,
          }
        );
      });
  };

  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          onClick={() => {
            onDownload();
          }}
        >
          Download
        </MenuItem>
      </Menu>
    </>
  );
};

export default MenuDialogMenu;
