import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';

const useStyle = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divBtn: {
    marginTop: theme.spacing(20),
  },
}));

const NotFound: React.FC = () => {
  const classes = useStyle();
  const router = useHistory();

  return (
    <div className={classes.root}>
      <Typography variant="h1">404</Typography>

      <Typography component="p" variant="body1">
        Nada Encontrado!
      </Typography>

      <div className={classes.divBtn}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => router.replace('/')}
        >
          Voltar para Página inicial
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
