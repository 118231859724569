import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { AuthProvider } from 'hooks/useAuth';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'routes';

import theme from 'styles/theme';

const App = () => {
  const handleOnIdle = () => {
    sessionStorage.removeItem('@GED/token');
    window.location.href =
      window.location.hostname === 'localhost'
        ? 'http://localhost:8080/login'
        : 'https://drive.blackredtechnology.com.br/login';
  };

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
