import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import api from 'services/api';

interface MenuInterface {
  id: string;
  name: string;
  type: string;
  loadContent: () => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const MenuDialogTrash = ({
  id,
  name,
  type,
  loadContent,
}: MenuInterface): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogDelete = () => {
    setOpenDialogDelete(!openDialogDelete);
  };

  const onDelete = async () => {
    setLoading(true);

    const response = await api.delete(`/trash/${id}`);

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'error',
        persist: false,
      });
    } else {
      enqueueSnackbar(
        `${type === 'folder' ? 'Pasta' : 'Arquivo'} excluido com sucesso.`,
        {
          variant: 'success',
          persist: false,
        }
      );
    }

    loadContent();
    handleClose();
    handleDialogDelete();
    setLoading(false);
  };

  const onRestore = async () => {
    let response: AxiosResponse;

    if (type === 'file') {
      response = await api.put(`/trash/restore/file/${id}`);
    } else {
      response = await api.put(`/trash/restore/folder/${id}`);
    }

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'error',
        persist: false,
      });
    } else {
      enqueueSnackbar(
        `${type === 'folder' ? 'Pasta' : 'Arquivo'} restaurado com sucesso.`,
        {
          variant: 'success',
          persist: false,
        }
      );
    }

    handleClose();
    loadContent();
  };

  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem onClick={onRestore}>Restaurar</MenuItem>
        <MenuItem onClick={handleDialogDelete}>Excluir</MenuItem>
      </Menu>

      <Dialog open={openDialogDelete}>
        <DialogTitle>
          Tem certeza que deseja excluir permanentemente{' '}
          {type === 'folder' ? 'a pasta' : 'o arquivo'} {name}?
        </DialogTitle>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              onClick={() => {
                handleDialogDelete();
                handleClose();
              }}
            >
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button color="primary" onClick={onDelete}>
              Excluir
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuDialogTrash;
