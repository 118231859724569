import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

interface PermissionDialogInterface {
  user: {
    id: string;
    companyId: string;
  };
  open: boolean;
  handleClose: () => void;
}

interface RenderTree {
  id: string;
  name: string;
  viewPermission: boolean;
  editPermission: boolean;
  deletePermission: boolean;
  downloadPermission: boolean;
  children: RenderTree[];
}

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 0, 0),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

const PermissionDialog = ({
  user,
  open,
  handleClose,
}: PermissionDialogInterface): JSX.Element => {
  const classes = useStyles();
  const [data, setData] = useState<RenderTree[]>([]);
  const [expanded, setExpanded] = React.useState<string[]>([]);

  const loadData = async () => {
    const response = await api.get(`/permission/${user.id}/${user.companyId}`);
    setData(response.data);
  };

  useEffect(() => {
    if (user.id) {
      loadData();
    }
  }, [user]);

  const handleTougle = (event, nodeIds) => {
    if (
      event.target.localName !== 'input' &&
      event.target.localName !== 'span'
    ) {
      setExpanded(nodeIds);
    }
  };

  const viewAdd = async (folderId) => {
    await api.put('/permission/view/add', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };
  const viewRemove = async (folderId) => {
    await api.put('/permission/view/remove', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };

  const editAdd = async (folderId) => {
    await api.put('/permission/edit/add', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };
  const editRemove = async (folderId) => {
    await api.put('/permission/edit/remove', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };

  const deleteAdd = async (folderId) => {
    await api.put('/permission/delete/add', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };
  const deleteRemove = async (folderId) => {
    await api.put('/permission/delete/remove', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };

  const downloadAdd = async (folderId) => {
    await api.put('/permission/download/add', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };
  const downloadRemove = async (folderId) => {
    await api.put('/permission/download/remove', {
      folderId,
      userId: user.id,
    });
    await loadData();
  };

  const renderTree = (nodes: RenderTree) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div className={classes.labelRoot}>
          <Typography className={classes.labelText}>{nodes.name}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={nodes.viewPermission}
                onChange={() =>
                  nodes.viewPermission
                    ? viewRemove(nodes.id)
                    : viewAdd(nodes.id)
                }
              />
            }
            label={<Typography variant="caption">Visualizar</Typography>}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={nodes.editPermission}
                onChange={() =>
                  nodes.editPermission
                    ? editRemove(nodes.id)
                    : editAdd(nodes.id)
                }
              />
            }
            label={<Typography variant="caption">Editar</Typography>}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={nodes.deletePermission}
                onChange={() =>
                  nodes.deletePermission
                    ? deleteRemove(nodes.id)
                    : deleteAdd(nodes.id)
                }
              />
            }
            label={<Typography variant="caption">Excluir</Typography>}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={nodes.downloadPermission}
                onChange={() =>
                  nodes.downloadPermission
                    ? downloadRemove(nodes.id)
                    : downloadAdd(nodes.id)
                }
              />
            }
            label={<Typography variant="caption">Download</Typography>}
            labelPlacement="start"
          />
        </div>
      }
    >
      {nodes.children.map((node) => renderTree(node))}
    </TreeItem>
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
        setExpanded([]);
      }}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>Permissões de Pastas</DialogTitle>
      <DialogContent>
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          onNodeToggle={handleTougle}
        >
          {data.map((node) => renderTree(node))}
        </TreeView>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            setExpanded([]);
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PermissionDialog;
