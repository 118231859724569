import { Box, Button, Paper, Typography } from '@material-ui/core';
import InputPassword from 'components/inputPassword';
import InputText from 'components/inputText';
import { useAuth } from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import blackRedLogo from 'assets/black_red_logo.png';

import authStyles from 'styles/auth';

interface Inputs {
  login: string;
  password: string;
}

const Login: React.FC = () => {
  const authUser = useAuth();
  const router = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = authStyles();
  const [showPassword, setShowPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    if (authUser.user) {
      router.push('/');
    }
  }, [authUser, router]);

  const onSubmit = async (values: Inputs) => {
    const { login, password } = values;

    const res = await authUser.signIn(login, password);

    if (res.data.error) {
      enqueueSnackbar('Login e/ou Senha incorreto ou não existe!', {
        variant: 'error',
        persist: false,
      });
    } else {
      enqueueSnackbar('Logado com sucesso!', {
        variant: 'success',
        persist: false,
      });
    }
  };

  const openPage = () => {
    window.open('https://www.blackredsistemas.com.br', '_blank');
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box textAlign="center">
          <Typography component="h1" variant="h5">
            Olá, Seja Muito Bem-Vindo ao Sistema GED - Gerenciamento Eletrônico
            de Documentos
          </Typography>
        </Box>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label="Login"
            error={!!errors.login?.message}
            helperText={errors.login?.message}
            {...register('login', { required: 'Este campo é obrigatório.' })}
          />
          <InputPassword
            label="Senha"
            typePassword={showPassword}
            changeTypePassword={() => setShowPassword(!showPassword)}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            {...register('password', {
              required: 'Este campo é obrigatório.',
            })}
          />

          <div className={classes.divBtn}>
            <Link to="/login/recover-password" className={classes.link}>
              <Button size="small">Esqueceu a Senha?</Button>
            </Link>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnSubmit}
              type="submit"
            >
              Entrar
            </Button>
          </div>
        </form>
      </Paper>

      <div
        className={classes.blackRedLogo}
        onClick={openPage}
        onKeyDown={openPage}
        role="button"
        tabIndex={0}
      >
        <Typography variant="subtitle1" component="p">
          Desenvolvido por
        </Typography>
        <img
          src={blackRedLogo}
          alt="Logo da Black Red Sistemas"
          height={60}
          width={120}
        />
        <Typography variant="subtitle1" component="p">
          GED - Digitalização e Gerenciamento de Documentos Eletrônicos
        </Typography>
      </div>
    </div>
  );
};

export default Login;
