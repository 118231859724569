import axios from 'axios';

const api = axios.create({
  baseURL:
    window.location.hostname === 'localhost'
      ? 'http://localhost:3000'
      : 'https://api-drive.blackredtechnology.com.br',
});

api.interceptors.request.use((config) => {
  if (sessionStorage.getItem('@GED/token')) {
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${sessionStorage.getItem(
      '@GED/token'
    )}`;
    return newConfig;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location.href =
        window.location.hostname === 'localhost'
          ? 'http://localhost:8080/login'
          : 'https://drive.blackredtechnology.com.br/login';
    }
    return error;
  }
);

export default api;
