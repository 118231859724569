import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { useAuth, UseAuthInterface } from './useAuth';

export default function useRequireAuth(): UseAuthInterface {
  const auth = useAuth();
  const router = useHistory();

  useEffect(() => {
    // null = Esperando resposta da API
    // false = Sem usuário logado
    if (auth.user === false) {
      router.push('/login');
    }
  }, [auth]);

  return auth;
}
