import { Paper, Typography } from '@material-ui/core';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import blackRedLogo from 'assets/black_red_logo.png';

import authStyles from 'styles/auth';

const EmailSuccess: React.FC = () => {
  const auth = useAuth();
  const router = useHistory();
  const classes = authStyles();

  useEffect(() => {
    if (auth.user) {
      router.push('/');
    }
  }, [auth, router]);

  const openPage = () => {
    window.open('https://www.blackredsistemas.com.br', '_blank');
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          E-mail Enviado
        </Typography>

        <Typography
          component="p"
          variant="body1"
          className={classes.infoEmailSuccess}
        >
          Um e-mail de recuperação de senha foi enviado para o endereço
          fornecido. Se um e-mail não chegar em breve, verifique sua pasta de
          spam. Se nenhum e-mail chegar, não existe uma conta com o e-mail
          informado.
        </Typography>
      </Paper>

      <div
        className={classes.blackRedLogo}
        onClick={openPage}
        onKeyDown={openPage}
        role="button"
        tabIndex={0}
      >
        <Typography variant="subtitle1" component="p">
          Desenvolvido por
        </Typography>
        <img src={blackRedLogo} alt="Logo da Black Red Sistemas" height={60} />
      </div>
    </div>
  );
};

export default EmailSuccess;
