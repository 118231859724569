import { fade, makeStyles } from '@material-ui/core';

import authBackground from 'assets/auth_background.png';

const authStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${authBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.common.white, 0.7),
    width: 550,
  },
  form: {
    marginTop: theme.spacing(1),
    width: 500,
  },
  divBtn: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  btnSubmit: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  subtitle: {
    paddingTop: theme.spacing(2),
  },
  infoEmailSuccess: {
    paddingTop: theme.spacing(3),
    width: 400,
    textAlign: 'justify',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  blackRedLogo: {
    position: 'fixed',
    bottom: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& p': {
      color: theme.palette.common.white,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
}));

export default authStyles;
