import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MenuDialogTrash from 'components/menuDialogTrash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

import fileImg from 'assets/file.png';
import folderEmptyImg from 'assets/folder_empty.png';
import folderImg from 'assets/folder.png';

const Trash: React.FC = () => {
  const [trash, setTrash] = useState([]);

  const loadContent = async () => {
    const response = await api.get(`/trash`);
    setTrash(response.data);
  };

  useEffect(() => {
    loadContent();
  }, []);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={120} />
            <TableCell>Descrição</TableCell>
            <TableCell align="center">Local Original</TableCell>
            <TableCell align="center">Data de Exclusão</TableCell>
            <TableCell align="center">Tamanho</TableCell>
            <TableCell align="center">Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trash.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                {row.type === 'file' ? (
                  <img src={fileImg} alt="Arquivo" height={50} />
                ) : (
                  <img
                    src={row.isEmpty ? folderEmptyImg : folderImg}
                    alt="Pasta"
                    height={50}
                  />
                )}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.path}</TableCell>
              <TableCell align="center">
                {moment(row.createdAt).format('DD/MM/YYYY HH:mm:ss')}
              </TableCell>
              <TableCell align="center">
                {row.size ? `${(row.size / 1000000).toFixed(2)} MB` : '---'}
              </TableCell>
              <TableCell align="center">
                <MenuDialogTrash
                  key={row.id}
                  id={row.id}
                  name={row.name}
                  type={row.type}
                  loadContent={loadContent}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Trash;
