import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Add, CloudUpload } from '@material-ui/icons';
import FormNewFolderDialog from 'components/formNewFolderDialog';
import MenuDialog from 'components/menuDialog';
import PdfDialog from 'components/pdfDialog';
import UploadDialog from 'components/uploadDialog';
import { useAuth, UserInterface } from 'hooks/useAuth';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import api from 'services/api';
import 'moment/locale/pt-br';

import fileImg from 'assets/file.png';
import folderEmptyImg from 'assets/folder_empty.png';
import folderImg from 'assets/folder.png';

import folderStyles from 'styles/folder';

const Folder: React.FC = () => {
  const { parentId } = useParams<Record<string, string | undefined>>();
  const auth = useAuth();
  const router = useHistory();
  const classes = folderStyles();
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [openDialogNewFolder, setOpenDialogNewFolder] = useState(false);
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [openDialogPdf, setOpenDialogPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');

  const loadContent = async () => {
    if (auth.user) {
      if ((auth.user as UserInterface).isAdmin) {
        const response = await api.get(`/folder/${parentId}`);
        setFolders(response.data.folders);
        setFiles(response.data.files);
        setPermissions(response.data.permissions);
      } else {
        const response = await api.get(
          `/folder/${parentId}/${(auth.user as UserInterface).id}`
        );
        setFolders(response.data.folders);
        setFiles(response.data.files);
        setPermissions(response.data.permissions);
      }
    }
  };

  useEffect(() => {
    loadContent();
  }, [parentId, auth.user]);

  const handleNewFolderDialog = () => {
    setOpenDialogNewFolder(!openDialogNewFolder);
  };

  const handleUploadDialog = () => {
    setOpenDialogUpload(!openDialogUpload);
  };

  const handlePdfDialog = () => {
    setOpenDialogPdf(!openDialogPdf);
  };

  const onClickFolder = (row) => {
    router.push(`/folder/${row.id}`);
  };

  const onClickFile = async (row) => {
    const response = await api.get(`/file/url/${row.id}`);

    setPdfUrl(response.data.url);
    handlePdfDialog();
  };

  return (
    <>
      {(auth.user as UserInterface)?.isAdmin === 1 && (
        <>
          <Toolbar className={classes.toolbarFolder} variant="dense">
            <Button startIcon={<Add />} onClick={handleNewFolderDialog}>
              Nova Pasta
            </Button>
            <Button
              startIcon={<CloudUpload />}
              onClick={handleUploadDialog}
              className={classes.btnUpload}
            >
              Upload
            </Button>
          </Toolbar>
        </>
      )}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={120} />
              <TableCell>Descrição</TableCell>
              <TableCell align="center">Modificado</TableCell>
              <TableCell align="center">Tamanho</TableCell>
              <TableCell align="center">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions?.view &&
              folders.map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell
                    onClick={() => {
                      onClickFolder(row);
                    }}
                  >
                    <img
                      src={row.isEmpty ? folderEmptyImg : folderImg}
                      alt="Pasta"
                      height={50}
                    />
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      onClickFolder(row);
                    }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      onClickFolder(row);
                    }}
                  >
                    {moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      onClickFolder(row);
                    }}
                  >
                    ---
                  </TableCell>
                  <TableCell align="center">
                    <MenuDialog
                      key={row.id}
                      id={row.id}
                      name={row.name}
                      type="folder"
                      loadContent={loadContent}
                      permissions={permissions}
                    />
                  </TableCell>
                </TableRow>
              ))}

            {permissions?.view &&
              files.map((row) => (
                <TableRow key={row.id} className={classes.row}>
                  <TableCell
                    onClick={() => {
                      onClickFile(row);
                    }}
                  >
                    <img src={fileImg} alt="Pasta" height={50} />
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      onClickFile(row);
                    }}
                  >
                    {row.fileName}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      onClickFile(row);
                    }}
                  >
                    {moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => {
                      onClickFile(row);
                    }}
                  >
                    {(row.size / 1000000).toFixed(2)} MB
                  </TableCell>
                  <TableCell align="center">
                    <MenuDialog
                      key={row.id}
                      id={row.id}
                      name={row.fileName}
                      type="file"
                      loadContent={loadContent}
                      permissions={permissions}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!permissions?.view && (
        <Typography className={classes.textNotPermission}>
          Você não possui permissão para visualizar conteúdo desta pasta.
        </Typography>
      )}

      <FormNewFolderDialog
        parentId={parentId}
        open={openDialogNewFolder}
        handleClose={handleNewFolderDialog}
        loadContent={loadContent}
      />

      <UploadDialog
        parentId={parentId}
        open={openDialogUpload}
        handleClose={handleUploadDialog}
        loadContent={loadContent}
      />

      <PdfDialog
        path={pdfUrl}
        open={openDialogPdf}
        handleClose={handlePdfDialog}
      />
    </>
  );
};

export default Folder;
