import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';

import InputText from './inputText';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Folder {
  name: string;
}

interface FormNewFolderInterface {
  parentId: string;
  open: boolean;
  handleClose: () => void;
  loadContent: () => void;
}

const FormNewFolderDialog = ({
  parentId,
  open,
  handleClose,
  loadContent,
}: FormNewFolderInterface): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, reset } = useForm<Folder>();

  const onSubmit = async (values: Folder) => {
    setLoading(true);

    const response = await api.post('/folder', {
      name: values.name,
      parent: parentId,
    });

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar('Pasta criada com sucesso', {
        variant: 'success',
        persist: false,
      });
      loadContent();
      handleClose();
      setTimeout(() => {
        reset();
      }, 195);
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>Nova Pasta</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Este campo é obrigatório.' }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="Nome"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  reset();
                }, 195);
              }}
              disabled={loading}
            >
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button type="submit" disabled={loading}>
              Criar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormNewFolderDialog;
