import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid';
import { Add, Delete, Edit } from '@material-ui/icons';
import FormCompanyDialog from 'components/formCompanyDialog';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

const useStyles = makeStyles((theme) => ({
  datagrid: {
    marginTop: theme.spacing(5),
    height: '80vh',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const Company: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [company, setCompany] = useState({
    id: '',
    razaoSocial: '',
    nomeFantasia: '',
    cnpj: '',
    inscricaoEstadual: '',
    email: '',
    celular: '',
    telefone: '',
    cep: '',
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
  });
  const [companyDelete, setCompanyDelete] = useState({
    id: '',
    nomeFantasia: '',
  });
  const [loading, setLoading] = useState(false);

  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  const handleDialogDeleteToggle = () => {
    setOpenDialogDelete(!openDialogDelete);
  };

  const loadCompanies = async () => {
    const response = await api.get('/company');

    setCompanies(response.data);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    const response = await api.delete(`/company/${companyDelete.id}`);
    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar('Empresa removida com sucesso.', {
        variant: 'success',
        persist: false,
      });
    }
    loadCompanies();
    handleDialogDeleteToggle();
    setLoading(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'razaoSocial',
      headerName: 'Razão Social',
      width: 250,
    },
    {
      field: 'nomeFantasia',
      headerName: 'Nome Fantasia',
      width: 250,
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 250,
    },
    {
      field: 'inscricaoEstadual',
      headerName: 'Inscrição Estadual',
      width: 250,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 250,
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      width: 250,
    },
    {
      field: 'cep',
      headerName: 'CEP',
      width: 250,
    },
    {
      field: 'logradouro',
      headerName: 'Logradouro',
      width: 250,
    },
    {
      field: 'numero',
      headerName: 'Número',
      width: 250,
    },
    {
      field: 'complemento',
      headerName: 'Complemento',
      width: 250,
    },
    {
      field: 'bairro',
      headerName: 'Bairro',
      width: 250,
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      width: 250,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 250,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      sortable: false,
      filterable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <div>
          <Tooltip title="Editar">
            <IconButton
              size="small"
              onClick={() => {
                setCompany({
                  id: params.row.id,
                  razaoSocial: params.row.razaoSocial,
                  nomeFantasia: params.row.nomeFantasia,
                  cnpj: params.row.cnpj,
                  inscricaoEstadual: params.row.inscricaoEstadual,
                  email: params.row.email,
                  celular: params.row.celular,
                  telefone: params.row.telefone,
                  cep: params.row.cep,
                  logradouro: params.row.logradouro,
                  numero: params.row.numero,
                  complemento: params.row.complemento,
                  bairro: params.row.bairro,
                  cidade: params.row.cidade,
                  estado: params.row.estado,
                });
                handleDialogToggle();
              }}
            >
              <Edit color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remover">
            <IconButton
              size="small"
              onClick={() => {
                setCompanyDelete({
                  id: params.row.id,
                  nomeFantasia: params.row.nomeFantasia,
                });
                handleDialogDeleteToggle();
              }}
            >
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Typography component="h1" variant="h4">
          Empresas
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setCompany({
              id: '',
              razaoSocial: '',
              nomeFantasia: '',
              cnpj: '',
              inscricaoEstadual: '',
              email: '',
              celular: '',
              telefone: '',
              cep: '',
              logradouro: '',
              numero: '',
              complemento: '',
              bairro: '',
              cidade: '',
              estado: '',
            });
            handleDialogToggle();
          }}
        >
          Nova Empresa
        </Button>
      </Grid>

      <DataGrid
        rows={companies}
        columns={columns}
        className={classes.datagrid}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
        }}
        autoPageSize
      />

      <FormCompanyDialog
        company={company}
        open={openDialog}
        handleClose={handleDialogToggle}
        loadCompanies={loadCompanies}
      />

      <Dialog open={openDialogDelete}>
        <DialogTitle>
          Tem certeza que deseja excluir a empresa {companyDelete.nomeFantasia}?
        </DialogTitle>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button color="primary" onClick={handleDialogDeleteToggle}>
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button color="primary" onClick={onDelete}>
              Excluir
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Company;
