import { Grid, makeStyles, Typography } from '@material-ui/core';
import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import 'moment/locale/pt-br';

const useStyles = makeStyles((theme) => ({
  datagrid: {
    marginTop: theme.spacing(5),
    height: '80vh',
  },
}));

const Log: React.FC = () => {
  const classes = useStyles();
  const [logs, setLogs] = useState([]);

  const loadLogs = async () => {
    const response = await api.get('/log');

    setLogs(response.data);
  };

  useEffect(() => {
    loadLogs();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      headerName: 'Data e Hora',
      width: 200,
      type: 'dateTime',
      valueFormatter: ({ value }) => {
        return moment(`${value}`).format('DD/MM/YYYY HH:mm:ss');
      },
    },
    {
      field: 'user',
      headerName: 'Usuário',
      width: 250,
      valueGetter: (params) => {
        return params.row.userId.name;
      },
    },
    {
      field: 'desc',
      headerName: 'Descrição',
      width: 1000,
    },
  ];

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Typography component="h1" variant="h4">
          Logs de Usuários
        </Typography>
      </Grid>

      <DataGrid
        rows={logs}
        columns={columns}
        className={classes.datagrid}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
        }}
        autoPageSize
        sortModel={[{ field: 'createdAt', sort: 'desc' }]}
      />
    </>
  );
};

export default Log;
