import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';

import InputPassword from './inputPassword';
import InputText from './inputText';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface User {
  id: string;
  name: string;
  email: string;
  login: string;
  isAdmin: number;
  companyId: string;
}

interface UserInput {
  id: string;
  name: string;
  email: string;
  login: string;
  password: string;
  confirmPassword: string;
  isAdmin: number;
  companyId: string;
}

interface FormUserInterface {
  user: User;
  open: boolean;
  handleClose: () => void;
  loadUsers: () => void;
}

const FormUserDialog = ({
  user,
  open,
  handleClose,
  loadUsers,
}: FormUserInterface): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { handleSubmit, control, reset, watch } = useForm<UserInput>();
  const passwordRef = useRef('');
  passwordRef.current = watch('password');

  const loadCompanies = async () => {
    const res = await api.get('/company');
    setCompanies(res.data);
  };

  useEffect(() => {
    loadCompanies();
  }, []);

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (values: UserInput) => {
    setLoading(true);

    let response: AxiosResponse;

    if (user.id) {
      response = await api.put(`/user/${user.id}`, values);
    } else {
      response = await api.post(`/user`, values);
    }

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar(
        `Usuário ${user.id ? 'editado' : 'criado'} com sucesso.`,
        {
          variant: 'success',
          persist: false,
        }
      );
      loadUsers();
      handleClose();
      setTimeout(() => {
        reset();
      }, 195);
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>{user.id ? 'Editar Usuário' : 'Novo Usuário'}</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                rules={{ required: 'Este campo é obrigatório.' }}
                defaultValue={user.name}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="Nome Completo"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="login"
                control={control}
                rules={{ required: 'Este campo é obrigatório.' }}
                defaultValue={user.login}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="Login"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Este campo é obrigatório.',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'E-mail inválido',
                  },
                }}
                defaultValue={user.email}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="E-mail"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputPassword
                    label="Senha"
                    typePassword={showPassword}
                    changeTypePassword={handleShowPasswordToggle}
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  validate: (value) =>
                    value === passwordRef.current || 'As senhas não coincidem',
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputPassword
                    label="Repita a Senha"
                    typePassword={showConfirmPassword}
                    changeTypePassword={handleShowConfirmPasswordToggle}
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="isAdmin"
                control={control}
                defaultValue={user.isAdmin}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Tipo da Conta</InputLabel>
                    <Select fullWidth {...field}>
                      <MenuItem value={0}>Usuário</MenuItem>
                      <MenuItem value={1}>Administrador</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="companyId"
                control={control}
                defaultValue={user.companyId}
                render={({ field }) => (
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Empresa</InputLabel>
                    <Select fullWidth {...field}>
                      <MenuItem value="0">Sem Empresa</MenuItem>
                      {companies.map((company) => {
                        return (
                          <MenuItem key={company.id} value={company.id}>
                            {company.nomeFantasia}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  reset();
                }, 195);
              }}
              disabled={loading}
            >
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button type="submit" disabled={loading}>
              Salvar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormUserDialog;
