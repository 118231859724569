/* eslint-disable jsx-a11y/label-has-associated-control */

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';

import profileBlackImg from '../assets/profile_black.png';
import { UserInterface } from '../hooks/useAuth';
import InputPassword from './inputPassword';
import InputText from './inputText';

interface FormProfileInterface {
  user: UserInterface;
  open: boolean;
  handleClose: () => void;
  loadUser: () => void;
}

interface Inputs {
  name: string;
  login: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  imgProfile: {
    borderRadius: theme.shape.borderRadius * 50,
  },
}));

const FormProfileDialog = ({
  user,
  open,
  handleClose,
  loadUser,
}: FormProfileInterface): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, watch, control, reset } = useForm<Inputs>({
    defaultValues: {
      name: user.name,
      login: user.login,
      email: user.email,
      password: '',
      confirmPassword: '',
    },
  });
  const passwordRef = useRef('');
  passwordRef.current = watch('password');
  const [photo, setPhoto] = useState({ file: null, url: user.photoUrl });

  const handleShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirmPasswordToggle = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (values: Inputs) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', photo.file);
    Object.keys(values).forEach((key) => formData.append(key, values[key]));

    const response = await api.put(`/user/${user.id}`, formData);

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar('Perfil editado com sucesso.', {
        variant: 'success',
        persist: false,
      });
      loadUser();
      handleClose();
      setTimeout(() => {
        const newData = values;
        newData.password = '';
        newData.confirmPassword = '';
        reset(newData);
        setPhoto({ file: null, url: photo.url });
      }, 195);
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>Editar Perfil</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <DialogContent>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={1}
          >
            <Grid item>
              <img
                src={photo.url ? `${photo.url}` : profileBlackImg}
                alt="Foto do perfil"
                width={100}
                height={100}
                className={classes.imgProfile}
              />
            </Grid>
            <Grid item>
              <input
                accept="image/*"
                id="raised-button-file"
                type="file"
                hidden
                onChange={(event) =>
                  setPhoto({
                    file: event.target.files[0],
                    url: URL.createObjectURL(event.target.files[0]),
                  })
                }
              />
              <label htmlFor="raised-button-file">
                <Button size="small" component="span">
                  Alterar Foto de Perfil
                </Button>
              </label>
            </Grid>
          </Grid>
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Este campo é obrigatório.' }}
            render={({ field, fieldState: { invalid, error } }) => (
              <InputText
                label="Nome Completo"
                error={invalid}
                helperText={error?.message}
                {...field}
              />
            )}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="login"
                control={control}
                rules={{ required: 'Este campo é obrigatório.' }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="Login"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: 'Este campo é obrigatório.',
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'E-mail inválido',
                  },
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="E-mail"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="password"
                control={control}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputPassword
                    label="Senha"
                    typePassword={showPassword}
                    changeTypePassword={handleShowPasswordToggle}
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  validate: (value) =>
                    value === passwordRef.current || 'As senhas não coincidem',
                }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputPassword
                    label="Repita a Senha"
                    typePassword={showConfirmPassword}
                    changeTypePassword={handleShowConfirmPasswordToggle}
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  reset();
                  setPhoto({ file: null, url: user.photoUrl });
                }, 195);
              }}
              disabled={loading}
            >
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button type="submit" disabled={loading}>
              Salvar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormProfileDialog;
