import { TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';

const InputText = React.forwardRef(
  (props: TextFieldProps, ref): JSX.Element => (
    <TextField innerRef={ref} fullWidth margin="normal" {...props} />
  )
);

export default InputText;
