import { Button, Paper, Typography } from '@material-ui/core';
import InputText from 'components/inputText';
import { useAuth } from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import blackRedLogo from 'assets/black_red_logo.png';

import authStyles from 'styles/auth';

interface Input {
  email: string;
}

const RecoverPassword: React.FC = () => {
  const auth = useAuth();
  const router = useHistory();
  const classes = authStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>();

  useEffect(() => {
    if (auth.user) {
      router.push('/');
    }
  }, [auth, router]);

  const onSubmit = async (values: Input) => {
    await auth.sendEmail(values.email);

    router.push('/login/email-success');
  };

  const openPage = () => {
    window.open('https://www.blackredsistemas.com.br', '_blank');
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Recuperação de Senha
        </Typography>

        <Typography component="p" variant="body1" className={classes.subtitle}>
          Informe o e-mail utilizado na criação da sua conta.
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <InputText
            label="E-mail"
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            {...register('email', {
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'E-mail inválido',
              },
              required: 'Este campo é obrigatório',
            })}
          />

          <div className={classes.divBtn}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Continuar
            </Button>
          </div>
        </form>
      </Paper>

      <div
        className={classes.blackRedLogo}
        onClick={openPage}
        onKeyDown={openPage}
        role="button"
        tabIndex={0}
      >
        <Typography variant="subtitle1" component="p">
          Desenvolvido por
        </Typography>
        <img src={blackRedLogo} alt="Logo da Black Red Sistemas" height={60} />
      </div>
    </div>
  );
};

export default RecoverPassword;
