import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import api from 'services/api';

import InputText from './inputText';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface Company {
  id: string;
  razaoSocial: string;
  nomeFantasia: string;
  cnpj: string;
  inscricaoEstadual: string;
  email: string;
  celular: string;
  telefone: string;
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

interface FormCompanyInterface {
  company: Company;
  open: boolean;
  handleClose: () => void;
  loadCompanies: () => void;
}

const FormCompanyDialog = ({
  company,
  open,
  handleClose,
  loadCompanies,
}: FormCompanyInterface): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, reset } = useForm<Company>();

  const onSubmit = async (values: Company) => {
    setLoading(true);

    let response: AxiosResponse;

    if (company.id) {
      response = await api.put(`/company/${company.id}`, values);
    } else {
      response = await api.post(`/company`, values);
    }

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar(
        `Empresa ${company.id ? 'editada' : 'criada'} com sucesso.`,
        {
          variant: 'success',
          persist: false,
        }
      );
      loadCompanies();
      handleClose();
      setTimeout(() => {
        reset();
      }, 195);
    }

    setLoading(false);
  };

  return (
    <Dialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>
        {company.id ? 'Editar Empresa' : 'Nova Empresa'}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="razaoSocial"
                control={control}
                rules={{ required: 'Este campo é obrigatório.' }}
                defaultValue={company.razaoSocial}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="Razão Social"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nomeFantasia"
                control={control}
                rules={{ required: 'Este campo é obrigatório.' }}
                defaultValue={company.nomeFantasia}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="Nome Fantasia"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="cnpj"
                control={control}
                rules={{ required: 'Este campo é obrigatório.' }}
                defaultValue={company.cnpj}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputMask mask="99.999.999/9999-99" {...field}>
                    {() => (
                      <InputText
                        label="CNPJ"
                        error={invalid}
                        helperText={error?.message}
                      />
                    )}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="inscricaoEstadual"
                control={control}
                defaultValue={company.inscricaoEstadual}
                render={({ field }) => (
                  <InputText label="Inscrição Estadual" {...field} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: 'E-mail inválido',
                  },
                }}
                defaultValue={company.email}
                render={({ field, fieldState: { invalid, error } }) => (
                  <InputText
                    label="E-mail"
                    error={invalid}
                    helperText={error?.message}
                    {...field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="celular"
                control={control}
                defaultValue={company.celular}
                render={({ field }) => (
                  <InputMask mask="(99) \99999-9999" {...field}>
                    {() => <InputText label="Celular" />}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="telefone"
                control={control}
                defaultValue={company.telefone}
                render={({ field }) => (
                  <InputMask mask="(99) 9999-9999" {...field}>
                    {() => <InputText label="Telefone" />}
                  </InputMask>
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Controller
                name="cep"
                control={control}
                defaultValue={company.cep}
                render={({ field }) => (
                  <InputMask mask="99999-999" {...field}>
                    {() => <InputText label="CEP" />}
                  </InputMask>
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="logradouro"
                control={control}
                defaultValue={company.logradouro}
                render={({ field }) => (
                  <InputText label="Logradouro" {...field} />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="numero"
                control={control}
                defaultValue={company.numero}
                render={({ field }) => <InputText label="Número" {...field} />}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="complemento"
                control={control}
                defaultValue={company.complemento}
                render={({ field }) => (
                  <InputText label="Complemento" {...field} />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="bairro"
                control={control}
                defaultValue={company.bairro}
                render={({ field }) => <InputText label="Bairro" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="cidade"
                control={control}
                defaultValue={company.cidade}
                render={({ field }) => <InputText label="Cidade" {...field} />}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="estado"
                control={control}
                defaultValue={company.estado}
                render={({ field }) => <InputText label="Estado" {...field} />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              onClick={() => {
                handleClose();
                setTimeout(() => {
                  reset();
                }, 195);
              }}
              disabled={loading}
            >
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button type="submit" disabled={loading}>
              Salvar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FormCompanyDialog;
