import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MenuDialogMenu from 'components/menuDialogDrive';
import { useAuth, UserInterface } from 'hooks/useAuth';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import api from 'services/api';

import folderEmptyImg from 'assets/folder_empty.png';
import folderImg from 'assets/folder.png';

import folderStyles from 'styles/folder';

const Dashboard: React.FC = () => {
  const classes = folderStyles();
  const [drives, setDrives] = useState([]);
  const router = useHistory();
  const auth = useAuth();

  const loadDrives = async () => {
    if (auth.user) {
      if ((auth.user as UserInterface).isAdmin) {
        const response = await api.get('/drive');
        setDrives(response.data);
      } else {
        const response = await api.get(
          `/drive/${(auth.user as UserInterface).companyId}`
        );
        setDrives(response.data);
      }
    }
  };

  useEffect(() => {
    loadDrives();
  }, [auth.user]);

  const onClickFolder = (row) => {
    router.push(`/folder/${row.id}`);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={120} />
            <TableCell>Descrição</TableCell>
            <TableCell align="center">Modificado</TableCell>
            <TableCell align="center">Tamanho</TableCell>
            <TableCell align="center">Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {drives.map((row) => (
            <TableRow key={row.id} className={classes.row}>
              <TableCell
                onClick={() => {
                  onClickFolder(row);
                }}
              >
                <img
                  src={row.isEmpty ? folderEmptyImg : folderImg}
                  alt="Pasta"
                  height={50}
                />
              </TableCell>
              <TableCell
                onClick={() => {
                  onClickFolder(row);
                }}
              >
                {row.companyId.nomeFantasia}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => {
                  onClickFolder(row);
                }}
              >
                {moment(row.updatedAt).format('DD/MM/YYYY HH:mm:ss')}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => {
                  onClickFolder(row);
                }}
              >
                ---
              </TableCell>
              <TableCell align="center">
                <MenuDialogMenu
                  key={row.id}
                  id={row.id}
                  name={row.companyId.nomeFantasia}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Dashboard;
