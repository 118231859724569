import { makeStyles } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useSnackbar } from 'notistack';
import React from 'react';
import api from 'services/api';

const useStyles = makeStyles(() => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}));

interface UploadDialogInterface {
  parentId: string;
  open: boolean;
  handleClose: () => void;
  loadContent: () => void;
}

const UploadDialog = ({
  parentId,
  open,
  handleClose,
  loadContent,
}: UploadDialogInterface): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (files) => {
    const formData = new FormData();
    formData.append('parent', parentId);
    Object.keys(files).forEach((key) => formData.append('files', files[key]));

    const response = await api.post('/file', formData);

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar('Arquivos enviados com sucesso.', {
        variant: 'success',
        persist: false,
      });
      loadContent();
      handleClose();
    }
  };

  return (
    <DropzoneDialog
      open={open}
      onSave={onSubmit}
      onClose={handleClose}
      acceptedFiles={['application/pdf']}
      dropzoneText="Arraste e solte um arquivo aqui ou clique no ícone"
      dialogTitle="Enviar Arquivos"
      cancelButtonText="Cancelar"
      submitButtonText="Enviar"
      filesLimit={20}
      maxFileSize={209715200}
      showPreviews
      showPreviewsInDropzone={false}
      useChipsForPreview
      previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
      previewChipProps={{ classes: { root: classes.previewChip } }}
      previewText="Arquivos selecionados"
      showAlerts={['error']}
      getFileLimitExceedMessage={(filesLimit) =>
        `Número máximo permitido de arquivos excedido. Apenas ${filesLimit} são permitidos.`
      }
    />
  );
};

export default UploadDialog;
