import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { AxiosResponse } from 'axios';
import download from 'downloadjs';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import api from 'services/api';

import InputText from './inputText';

interface MenuInterface {
  id: string;
  name: string;
  type: string;
  loadContent: () => void;
  permissions: {
    view: boolean;
    edit: boolean;
    delete: boolean;
    download: boolean;
  };
}

interface Input {
  name: string;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const MenuDialog = ({
  id,
  name,
  type,
  loadContent,
  permissions,
}: MenuInterface): JSX.Element => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, reset } = useForm<Input>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDialogDelete = () => {
    setOpenDialogDelete(!openDialogDelete);
  };

  const onSubmit = async (values: Input) => {
    setLoading(true);

    let response: AxiosResponse;

    if (type === 'folder') {
      response = await api.put(`/folder/${id}`, {
        name: values.name,
      });
    } else {
      response = await api.put(`/file/${id}`, {
        name: values.name,
      });
    }

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar(
        `${type === 'folder' ? 'Pasta' : 'Arquivo'} renomeado com sucesso.`,
        {
          variant: 'success',
          persist: false,
        }
      );
      loadContent();
      handleDialog();
      handleClose();
      setTimeout(() => {
        reset();
      }, 195);
    }

    setLoading(false);
  };

  const deleteItem = async () => {
    setLoading(true);

    let response: AxiosResponse;

    if (type === 'file') {
      response = await api.put(`/file/trash/${id}`);
    } else {
      response = await api.put(`/folder/trash/${id}`);
    }

    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'error',
        persist: false,
      });
    } else {
      enqueueSnackbar(
        `${type === 'folder' ? 'Pasta' : 'Arquivo'} excluido com sucesso.`,
        {
          variant: 'success',
          persist: false,
        }
      );
    }

    loadContent();
    handleClose();
    handleDialogDelete();
    setLoading(false);
  };

  const onDownload = async () => {
    handleClose();

    if (type === 'file') {
      enqueueSnackbar('Verificando e preparando o arquivo para donwload...', {
        variant: 'info',
        persist: false,
      });

      const response = await api.get(`/file/download/${id}`, {
        responseType: 'blob',
      });

      if (response.data.error) {
        enqueueSnackbar(response.data.error, {
          variant: 'error',
          persist: false,
        });
      } else {
        download(response.data, name);
      }
    } else {
      enqueueSnackbar('Verificando e preparando a pasta para donwload...', {
        variant: 'info',
        persist: false,
      });

      api
        .get(`/folder/download/${id}`, {
          responseType: 'blob',
        })
        .then((res) => {
          download(res.data, `${name}.zip`);
        })
        .catch(() => {
          enqueueSnackbar(
            'Você não possui permissão para realizar o download desta pasta.',
            {
              variant: 'warning',
              persist: false,
            }
          );
        });
    }
  };

  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <MoreVert />
      </IconButton>

      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          onClick={() => {
            if (permissions?.edit) {
              handleDialog();
            } else {
              enqueueSnackbar(
                `Você não possui permissão para editar
                ${type === 'folder' ? 'esta pasta' : 'este arquivo'}.`,
                {
                  variant: 'warning',
                  persist: false,
                }
              );
              handleClose();
            }
          }}
        >
          Renomear
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (permissions?.delete) {
              handleDialogDelete();
            } else {
              enqueueSnackbar(
                `Você não possui permissão para excluir
              ${type === 'folder' ? 'esta pasta' : 'este arquivo'}.`,
                {
                  variant: 'warning',
                  persist: false,
                }
              );
              handleClose();
            }
          }}
        >
          Excluir
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (permissions?.download) {
              onDownload();
            } else {
              enqueueSnackbar(
                'Você não possui permissão para baixar este arquivo.',
                {
                  variant: 'warning',
                  persist: false,
                }
              );
              handleClose();
            }
          }}
        >
          Download
        </MenuItem>
      </Menu>

      <Dialog open={openDialog} maxWidth="xs" fullWidth>
        <DialogTitle>Renomear</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue={name}
                  rules={{ required: 'Este campo é obrigatório.' }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <InputText
                      label="Nome"
                      error={invalid}
                      helperText={error?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <div className={classes.wrapper}>
              <Button
                onClick={() => {
                  handleDialog();
                  handleClose();
                  setTimeout(() => {
                    reset();
                  }, 195);
                }}
                disabled={loading}
              >
                Cancelar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
            <div className={classes.wrapper}>
              <Button type="submit" disabled={loading}>
                Renomear
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog open={openDialogDelete}>
        <DialogTitle>
          Tem certeza que deseja excluir{' '}
          {type === 'folder' ? 'a pasta' : 'o arquivo'} {name}?
        </DialogTitle>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button
              color="primary"
              onClick={() => {
                handleDialogDelete();
                handleClose();
              }}
            >
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button color="primary" onClick={deleteItem}>
              Excluir
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MenuDialog;
