import { AxiosResponse } from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import api from 'services/api';

function useProvideAuth() {
  // null = Esperando resposta da API
  // false = Sem usuário logado
  const [user, setUser] = useState(null);

  const signIn = async (login: string, password: string) => {
    const response = await api.post('/auth', { login, password });

    if (response.data.token) {
      sessionStorage.setItem('@GED/token', response.data.token);
      setUser(response.data.user);
    }

    return response;
  };

  const signOut = async () => {
    sessionStorage.removeItem('@GED/token');
    setUser(false);
  };

  const sendEmail = async (email: string) => {
    const response = await api.get(`/auth/recover/${email}`);

    return response;
  };

  const newPassword = async (password: string) => {
    const response = await api.post('/auth/new-password', { password });

    return response;
  };

  const checkLogin = async () => {
    const token = sessionStorage.getItem('@GED/token');
    if (token) {
      const response = await api.get(`/auth/get-user/${token}`);

      if (!response.data.error) {
        setUser(response.data);
        return;
      }
    }
    sessionStorage.removeItem('@GED/token');
    setUser(false);
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return {
    user,
    signIn,
    signOut,
    sendEmail,
    newPassword,
    checkLogin,
  };
}

export interface UserInterface {
  id: string;
  name: string;
  email: string;
  login: string;
  photoUrl: string;
  isAdmin: number;
  companyId: string;
}

export interface UseAuthInterface {
  // null = Esperando resposta da API
  // false = Sem usuário logado
  // UserInterface = Possui usuário logado
  user: null | boolean | UserInterface;
  signIn: (login: string, password: string) => Promise<AxiosResponse>;
  signOut: () => Promise<void>;
  sendEmail: (email: string) => Promise<AxiosResponse>;
  newPassword: (password: string) => Promise<AxiosResponse>;
  checkLogin: () => Promise<void>;
}

export const AuthContext = createContext<UseAuthInterface>(
  {} as UseAuthInterface
);

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export function useAuth(): UseAuthInterface {
  return useContext(AuthContext);
}
