import { makeStyles, Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const TooltipCustom = (props: TooltipProps): JSX.Element => {
  const classes = useStyles();

  return <Tooltip arrow classes={classes} {...props} />;
};

export default TooltipCustom;
