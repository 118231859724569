import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DataGrid, GridColDef, GridToolbar } from '@material-ui/data-grid';
import { Add, Delete, Edit, VpnKey } from '@material-ui/icons';
import FormUserDialog from 'components/formUserDialog';
import PermissionDialog from 'components/permissionDialog';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import api from 'services/api';

const useStyles = makeStyles((theme) => ({
  datagrid: {
    marginTop: theme.spacing(5),
    height: '80vh',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const User: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogPermission, setOpenDialogPermission] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({
    id: '',
    name: '',
    login: '',
    email: '',
    isAdmin: 0,
    companyId: '0',
  });
  const [userDelete, setUserDelete] = useState({
    id: '',
    name: '',
  });
  const [userPermission, setUserPermission] = useState({
    id: '',
    companyId: '',
  });
  const [loading, setLoading] = useState(false);

  const handleDialogToggle = () => {
    setOpenDialog(!openDialog);
  };

  const handleDialogDeleteToggle = () => {
    setOpenDialogDelete(!openDialogDelete);
  };

  const handleDialogPermissionToggle = () => {
    setOpenDialogPermission(!openDialogPermission);
  };

  const loadUsers = async () => {
    const response = await api.get('/user');

    setUsers(response.data);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const onDelete = async () => {
    setLoading(true);
    const response = await api.delete(`/user/${userDelete.id}`);
    if (response.data.error) {
      enqueueSnackbar(response.data.error, {
        variant: 'warning',
        persist: false,
      });
    } else {
      enqueueSnackbar('Usuário removida com sucesso.', {
        variant: 'success',
        persist: false,
      });
    }
    loadUsers();
    handleDialogDeleteToggle();
    setLoading(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Nome',
      width: 250,
    },
    {
      field: 'login',
      headerName: 'Login',
      width: 250,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 250,
    },
    {
      field: 'isAdmin',
      headerName: 'Tipo de Conta',
      width: 250,
      valueFormatter: (params) =>
        params.value === 0 ? 'Usuário' : 'Administrador',
    },
    {
      field: 'empresa',
      headerName: 'Empresa',
      width: 250,
      valueGetter: (params) => {
        if (params.row.companyId) {
          return params.row.companyId.nomeFantasia;
        }
        return 'Sem Empresa';
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      align: 'right',
      width: 120,
      sortable: false,
      filterable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        const { row } = params;
        const { isAdmin } = row;

        return (
          <div>
            {!isAdmin && (
              <Tooltip title="Permissões">
                <IconButton
                  size="small"
                  onClick={() => {
                    setUserPermission({
                      id: row.id,
                      companyId: row.companyId ? row.companyId.id : null,
                    });
                    handleDialogPermissionToggle();
                  }}
                >
                  <VpnKey color="primary" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Editar">
              <IconButton
                size="small"
                onClick={() => {
                  setUser({
                    id: row.id,
                    name: row.name,
                    login: row.login,
                    email: row.email,
                    isAdmin: row.isAdmin,
                    companyId: row.companyId ? row.companyId.id : '0',
                  });
                  handleDialogToggle();
                }}
              >
                <Edit color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Remover">
              <IconButton
                size="small"
                onClick={() => {
                  setUserDelete({
                    id: row.id,
                    name: row.name,
                  });
                  handleDialogDeleteToggle();
                }}
              >
                <Delete color="error" />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <Typography component="h1" variant="h4">
          Usuários
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setUser({
              id: '',
              name: '',
              login: '',
              email: '',
              isAdmin: 0,
              companyId: '0',
            });
            handleDialogToggle();
          }}
        >
          Novo Usuário
        </Button>
      </Grid>

      <DataGrid
        rows={users}
        columns={columns}
        className={classes.datagrid}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
        }}
        autoPageSize
      />

      <FormUserDialog
        user={user}
        open={openDialog}
        handleClose={handleDialogToggle}
        loadUsers={loadUsers}
      />

      <Dialog open={openDialogDelete}>
        <DialogTitle>
          Tem certeza que deseja excluir o usuário {userDelete.name}?
        </DialogTitle>
        <DialogActions>
          <div className={classes.wrapper}>
            <Button color="primary" onClick={handleDialogDeleteToggle}>
              Cancelar
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
          <div className={classes.wrapper}>
            <Button color="primary" onClick={onDelete}>
              Excluir
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </DialogActions>
      </Dialog>

      <PermissionDialog
        user={userPermission}
        open={openDialogPermission}
        handleClose={handleDialogPermissionToggle}
      />
    </>
  );
};

export default User;
